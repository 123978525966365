import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse, AxiosError } from "axios";
import classNames from "classnames";
import { deleteConnectedAccount, getConnectedAccounts, getUnwrappedConnectedAccounts } from "../../api/Profile";
import Footer from "../../components/Footer";
import { useAuth } from "../../contexts/AuthProvider";
import EmailAccordion from "./components/EmailAccordion";
import NameAccordion from "./components/NameAccordion";
import PasswordAccordion from "./components/PasswordAccordion";
import UsernameAccordion from "./components/UsernameAccordion";

export default function Profile() {

  const queryClient = useQueryClient();

  const { profile, isLoading: profileLoading, isFetching: profileFetching } = useAuth();

  const { isLoading: connectedAccountsLoading,
    isError,
    error,
    data: connectedAccounts,
    isSuccess: connectedAccountsSuccess,
    isFetching,
    isPreviousData, } = useQuery(['connected_accounts'], () => getUnwrappedConnectedAccounts(), { refetchOnWindowFocus: true });

  const { isLoading: unlinkLoading, isSuccess: unlinkSuccess, error: unlinkError, mutate: unlinkProvider } = useMutation<AxiosResponse<void, any>, AxiosError, string>((id: string) => {
    return deleteConnectedAccount(id);
  }, {
    // this object is a MutateOptions
    onSuccess: async () => {
      queryClient.invalidateQueries(['connected_accounts'])
    }
  });



  const loggedInWithGithub = profile?.oauth_registration_id === 'github';
  const linkedWithGithub = connectedAccounts?.find(({ providerName }) => providerName === 'github');

  const loggedInWithGoogle = profile?.oauth_registration_id === 'google';
  const linkedWithGoogle = connectedAccounts?.find(({ providerName }) => providerName === 'google');


  const performAction = (providerName: string, link: boolean) => {
    if (!link) unlinkProvider(providerName);
    else if (providerName === 'google') window.open([process.env.REACT_APP_GOOGLE_AUTH_URL, process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL + '/profile'].join(""));
    else if (providerName === 'github') window.open([process.env.REACT_APP_GITHUB_AUTH_URL, process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL + '/profile'].join(""));
  }

  return <div className="mx-auto md:px-10 px-5">
    <h1 className="pt-20 pb-10 subpixel-antialiased font-semibold text-5xl">Profile</h1>

    {/* <Input placeholder="sample@email.com" /> */}
    <div className="md:w-3/4 max-w-2xl pb-5">
      <UsernameAccordion />
      <NameAccordion />
      <PasswordAccordion />
      <EmailAccordion />
    </div>

    <h3 className={classNames("font-semibold text-4xl", { "loading": connectedAccountsLoading })}>Connected accounts</h3>


    <div className="py-2">
      <button onClick={() => { performAction('github', !linkedWithGithub) }} disabled={loggedInWithGithub || connectedAccountsLoading || unlinkLoading || !connectedAccountsSuccess} type="button" className="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 mr-2 mb-2">
        <svg className="mr-2 -ml-1 w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
        {!linkedWithGithub ? 'Sign in with Github' : 'Unlink Github account'}{linkedWithGithub && <span className="font-bold pl-5">{linkedWithGithub.providerInfoName}</span>}
      </button>

      {loggedInWithGithub && <div className="text-sm">You are logged in using this account.</div>}
    </div>

    <div className="py-2">
      <button onClick={() => { performAction('google', !linkedWithGoogle) }} disabled={loggedInWithGoogle || connectedAccountsLoading || unlinkLoading || !connectedAccountsSuccess} type="button" className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
        <svg className="mr-2 -ml-1 w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
        {!linkedWithGoogle ? 'Sign in with Google' : 'Unlink Google account'}{linkedWithGoogle && <span className="font-bold pl-5">{linkedWithGoogle.providerInfoName}</span>}
      </button>


      {loggedInWithGoogle && <div className="text-sm">You are logged in using this account.</div>}
    </div>

    <Footer />

  </div>
}